// pages/Cart.jsx
import React, { useContext, useEffect, useState } from "react";
import { CartContext } from "../context/CartContext";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { blue } from "@mui/material/colors";
import { useLanguage } from "../context/LanguageContext";
import PopupMessage from "../components/PopupMessage/PopupMessage";
import Loader from "../components/Loader/Loader";
import ScrollToTop from "../components/ScrollToTop";

const EVENTEN_API_URL = process.env.REACT_APP_CONFIRMATION_EMAIL;

const StyledContainer = styled.div`
  display: flex;
  width: 95vw;
  justify-content: space-evenly;

  @media (max-width: 699px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const StyledBtn = styled.button`
  width: 1px;
  height: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  padding: 10px;
  background-color: #ffdcdc0e;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  color: grey;
`;

const StyledLi = styled.li`
  display: flex;
  flex-direction: column;
  width: 25vw;
  margin-bottom: 1.5rem;

  @media (max-width: 699px) {
    width: 50vw;
  }
`;

const StyledImg = styled.img`
  width: 100%;
  cursor: pointer;
`;

const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 699px) {
    margin-top: 2rem;
  }
`;

const StyledPayDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 50vw;
  background-color: #ffdcdc0e;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  padding: 20px;
  border-radius: 20px;
  height: fit-content;
  margin-top: 1rem;

  @media (max-width: 699px) {
    width: 80vw;
  }
`;

const StyledNumDiv = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

const StyledH3 = styled.h3`
  font-size: 1.1rem;
  font-weight: 400;
  margin: 0.75rem;

  @media (max-width: 799px) {
    font-size: 1rem;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  input,
  select {
    width: 100%;
    padding: 0.5rem;
    margin: 0.5rem 0;
  }
`;

const StyledFormGroup = styled.div`
  display: flex;
  border-radius: 10px;
  margin: 0.5rem 0;
  border: 0.1px solid lightgrey;
  align-items: center;
  background-color: white;

  label {
    opacity: 0.5;
    background-color: white;
    margin: 0.5rem 1rem;
    width: 58px;
  }

  input {
    width: 80%;
    border: none;
    outline: none;
  }
`;

const StyledButton = styled.button`
  background-color: #0058a0;
  color: white;
  padding: 0.75rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto;
  margin-top: 1rem;

  &:hover {
    background-color: #0056b3;
  }
`;

const StyledTermsDiv = styled.div`
  display: flex;
  width: fit-content;
  gap: 5px;

  @media (max-width: 439px) {
    flex-direction: column;
  }
`;

const StyledP = styled.p`
@media (max-width: 439px) {
margin: -.5rem 0 1rem 2.7rem;
`;

const StyledDivContainer = styled.div`
  min-height: 75vh;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 699px) {
    margin-top: -1rem;
  }
`;

const TermsDialog = ({ open, onAgree, onClose }) => {
  const { getTranslation } = useLanguage();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      style={{ zIndex: "100000" }}
    >
      <DialogTitle id="alert-dialog-title">
        {getTranslation("cart.termsAndConditions")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <p>{getTranslation("cart.terms1")}</p>
          <p>{getTranslation("cart.terms2")}</p>
          <p>{getTranslation("cart.terms3")}</p>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{getTranslation("cart.disagree")}</Button>
        <Button onClick={onAgree} autoFocus>
          {getTranslation("cart.agree")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Cart = () => {
  const [subTotal, setSubTotal] = useState(0);
  const { cart, removeFromCart, getTotalAmount } = useContext(CartContext);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const { getTranslation } = useLanguage();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showPopupMessage, setShowPopupMessage] = useState(false); // For success message
  const [disableBtn, setDisableBtn] = useState(false);
  const [loading, setLoading] = useState(false);

  ScrollToTop();

  useEffect(() => {
    let total = 0;
    cart.forEach((item) => {
      total += 1999;
    });
    setSubTotal(total);
  }, [cart]);

  const handleClick = (product) => {
    navigate(`/product-page`, { state: { product } });
  };

  const handleTermsOpen = () => {
    setOpen(true);
  };

  const handleTermsClose = () => {
    setOpen(false);
  };

  const handleTermsAgree = () => {
    setTermsChecked(true);
    handleTermsClose();
  };

  // const handlePayment = (e) => {
  //   e.preventDefault();
  //   if (termsChecked) {
  //     console.log(name, email);
  //     cart.forEach((item) => console.log(item.name));
  //   } else {
  //     alert(
  //       getTranslation("cart.agreeTerms") +
  //         " " +
  //         getTranslation("cart.termsAndConditions")
  //     );
  //   }
  // };

  const handlePayment = async (e) => {
    setLoading(true);
    setDisableBtn(true);
    e.preventDefault();

    if (name.length === 0) {
      // alert(getTranslation("cart.nameRequired"));
      setMessage(getTranslation("cart.nameRequired"));
      setShowPopupMessage(true);
      setTimeout(() => {
        setShowPopupMessage(false);
      }, 3500);
      setLoading(false);
      setDisableBtn(false);
      return;
    }

    if (email.length === 0 || !/\S+@\S+\.\S+/.test(email)) {
      // alert(getTranslation("cart.validEmailRequired"));
      setMessage(getTranslation("cart.validEmailRequired"));
      setShowPopupMessage(true);
      setTimeout(() => {
        setShowPopupMessage(false);
      }, 3500);
      setLoading(false);

      setDisableBtn(false);

      return;
    }

    if (!termsChecked) {
      // alert(
      //   getTranslation("cart.agreeTerms") +
      //     " " +
      //     getTranslation("cart.termsAndConditions")
      // );
      setMessage(
        getTranslation("cart.agreeTerms") +
          " " +
          getTranslation("cart.termsAndConditions")
      );
      setShowPopupMessage(true);
      setTimeout(() => {
        setShowPopupMessage(false);
      }, 3500);
      setLoading(false);

      setDisableBtn(false);

      return;
    }

    const cartItems = cart.map((item) => ({
      name: item.name,
      price: item.price,
    }));

    try {
      const response = await fetch(EVENTEN_API_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          terms: termsChecked,
          cart: cartItems,
          discount: subTotal - getTotalAmount(),
          totalPrice: getTotalAmount(),
        }),
      });

      const data = await response.json();

      if (data.status === "success") {
        // alert(getTranslation("cart.orderConfirmed"));
        setMessage(getTranslation("cart.orderConfirmed"));
        setShowPopupMessage(true);
        setTimeout(() => {
          setShowPopupMessage(false);
        }, 3500);
        setTimeout(() => {
          setDisableBtn(false);
          setLoading(false);

          navigate("/confirmation", {
            state: {
              name,
              orderNumber: data.orderNumber,
              cartItems,
              totalPrice: getTotalAmount(),
              discount: subTotal - getTotalAmount(),
            },
          });
        }, 2000);
      } else {
        // alert(getTranslation("cart.orderFailed"));
        setMessage(getTranslation("cart.orderFailed"));
        setShowPopupMessage(true);
        setTimeout(() => {
          setShowPopupMessage(false);
        }, 3500);
        setLoading(false);

        setDisableBtn(false);
      }
    } catch (error) {
      console.error(error);
      // alert(getTranslation("cart.orderFailed"));
      setMessage(getTranslation("cart.orderFailed"));
      setShowPopupMessage(true);
      setTimeout(() => {
        setShowPopupMessage(false);
      }, 3500);
      setLoading(false);

      setDisableBtn(false);
    }
  };

  return (
    <StyledDivContainer>
      {showPopupMessage && <PopupMessage message={message} />}
      {loading && <Loader />}
      <h2
        style={{
          textAlign: "center",
          textShadow: "0px 1px 1px rgba(0, 0, 0, 0.3)",
        }}
      >
        {getTranslation("cart.yourCart")}
      </h2>
      {cart.length === 0 ? (
        <p style={{ textAlign: "center", marginTop: "8rem" }}>
          {getTranslation("cart.noItems")}
        </p>
      ) : (
        <StyledContainer>
          <ul style={{ padding: 0 }}>
            {cart.map((item, index) => (
              <StyledLi key={index}>
                <StyledDiv>
                  {item.name}
                  <StyledBtn onClick={() => removeFromCart(item)}>X</StyledBtn>
                </StyledDiv>
                <p
                  style={{
                    fontSize: ".8rem",
                    opacity: ".8",
                    margin: "0 0 1rem",
                  }}
                >
                  {item.price}
                </p>

                <StyledImg
                  onClick={() => handleClick(item)}
                  src={item.image}
                  alt={item.name}
                />
              </StyledLi>
            ))}
          </ul>
          <StyledPayDiv>
            <StyledNumDiv>
              <StyledH3>{getTranslation("cart.subtotal")}</StyledH3>
              <StyledH3>{subTotal},-</StyledH3>
            </StyledNumDiv>
            <StyledNumDiv>
              <StyledH3>{getTranslation("cart.discount")}</StyledH3>
              <StyledH3 style={{ color: "green" }}>
                - {subTotal - getTotalAmount()},-
              </StyledH3>
            </StyledNumDiv>
            <StyledNumDiv>
              <StyledH3 style={{ fontWeight: 600 }}>
                {getTranslation("cart.total")}
              </StyledH3>
              <StyledH3 style={{ fontWeight: 600 }}>
                {getTotalAmount()},-
              </StyledH3>
            </StyledNumDiv>
            <p style={{ textAlign: "center" }}>
              {getTranslation("cart.payWhen")}
            </p>
            <StyledForm>
              <StyledFormGroup>
                <label style={{ background: "transparent" }}>
                  {getTranslation("cart.name")}
                </label>
                <input
                  type="text"
                  autoComplete="name"
                  placeholder="Jenny Rosen"
                  onChange={(e) => setName(e.target.value)}
                />
              </StyledFormGroup>
              <StyledFormGroup>
                <label style={{ background: "transparent" }}>
                  {getTranslation("cart.email")}
                </label>
                <input
                  type="email"
                  autoComplete="email"
                  placeholder="jenny.rosen@example.com"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </StyledFormGroup>
              <StyledTermsDiv>
                <FormControlLabel
                  style={{
                    opacity: ".7",
                    marginLeft: ".1px",
                    marginRight: 0,
                  }}
                  control={
                    <Checkbox
                      checked={termsChecked}
                      onChange={() => setTermsChecked(!termsChecked)}
                      name="terms"
                      color="primary"
                    />
                  }
                  label={<span>{getTranslation("cart.agreeTerms")}</span>}
                />
                <StyledP
                  style={{
                    color: blue[500],
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={handleTermsOpen}
                >
                  {getTranslation("cart.termsAndConditions")}
                </StyledP>
              </StyledTermsDiv>
              <StyledButton onClick={handlePayment} disabled={disableBtn}>
                {getTranslation("cart.orderNow")}
              </StyledButton>
            </StyledForm>
          </StyledPayDiv>
          <TermsDialog
            open={open}
            onAgree={handleTermsAgree}
            onClose={handleTermsClose}
          />
        </StyledContainer>
      )}
    </StyledDivContainer>
  );
};

export default Cart;
