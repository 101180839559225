// components/Header.jsx
import React from "react";
import { Link } from "react-router-dom";
import norwayFlag from "../assets/images/norway-flag.png";
import usFlag from "../assets/images/us-flag.png";
import styled from "styled-components";
import HomeIcon from "../assets/images/logo.png";
import CartIcon from "../assets/images/cart-icon.png";
import { useLanguage } from "../context/LanguageContext";

const StyledDiv = styled.div`
  position: absolute;
  top: 2.1rem;
  right: 2.5rem;

  @media (max-width: 699px) {
    top: 1.5rem;
    right: 1.8rem;
  }
`;

const StyledIcon = styled.img`
  min-width: 25px;
  width: 5vw;
  max-width: 30px;
`;

const FlagContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  position: fixed;
  bottom: 2rem;
  right: 0.75rem;
  z-index: 10000000;
  padding: 10px;
`;

const StyledImg = styled.img`
  min-width: 23px;
  width: 3vw;
  max-width: 30px;
`;

const StyledLogo = styled.img`
  min-width: 100px;
  width: 10vw;
  max-width: 130px;
  position: absolute;
  top: 2.5rem;
  left: 2.5rem;

  @media (max-width: 699px) {
    top: 1.75rem;
    left: 1.8rem;
  }
`;

const Header = () => {
  const { changeLanguage } = useLanguage();

  return (
    <header style={{ marginBottom: "6rem" }}>
      <Link to="/">
        <StyledLogo src={HomeIcon} alt="Home" />
      </Link>

      <StyledDiv>
        <Link to="/cart">
          <StyledIcon src={CartIcon} alt="Cart" />
        </Link>
        <FlagContainer onClick={changeLanguage}>
          <StyledImg src={norwayFlag} alt="Norwegian" />
          <StyledImg src={usFlag} alt="English" />
        </FlagContainer>
      </StyledDiv>
    </header>
  );
};

export default Header;
