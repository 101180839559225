import React, { createContext, useState, useEffect } from "react";
import PopupMessage from "../components/PopupMessage/PopupMessage";
import { useLanguage } from "../context/LanguageContext";

export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [showPopupMessage, setShowPopupMessage] = useState(false); // For success message
  const { getTranslation } = useLanguage();

  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem("eventenCart");
    return savedCart ? JSON.parse(savedCart) : [];
  });

  useEffect(() => {
    localStorage.setItem("eventenCart", JSON.stringify(cart));
  }, [cart]);

  const addToCart = (product) => {
    setShowPopupMessage(true);
    setTimeout(() => {
      setShowPopupMessage(false);
    }, 3500);
    if (!cart.some((item) => item.id === product.id)) {
      setCart([...cart, product]);
    }
  };

  const removeFromCart = (product) => {
    setCart(cart.filter((item) => item.id !== product.id));
  };

  const clearCart = () => {
    setCart([]);
  };

  const getTotalAmount = () => {
    if (cart.length === 1) return 1999;
    if (cart.length === 2) return 3799;
    if (cart.length === 3) return 4999;
    if (cart.length === 4) return 5999;
    return cart.reduce((total, item) => total + item.price, 0);
  };

  return (
    <CartContext.Provider
      value={{ cart, addToCart, removeFromCart, clearCart, getTotalAmount }}
    >
      {showPopupMessage && (
        <PopupMessage message={getTranslation("cart.toCart")} />
      )}
      {children}
    </CartContext.Provider>
  );
};
