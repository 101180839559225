// pages/HomePage.jsx
import React from "react";
import ProductCard from "../components/ProductCard";
import styled from "styled-components";
import { useLanguage } from "../context/LanguageContext";
import Banner from "../assets/images/banner.png";
import ScrollToTop from "../components/ScrollToTop";

const StyledDiv = styled.div`
  display: flex;
  min-height: 70vh;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 2rem;

  @media (max-width: 999px) {
    flex-direction: column;
    align-items: center;
  }
`;

const HeadingsDiv = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
`;

const HeadingsH2 = styled.h2`
  padding: 20px 30px;
  border-radius: 20px;
  background-color: #ffdcdc0e;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.3);
  font-size: 1.4rem;

  @media (max-width: 799px) {
    font-size: 1rem;
    padding: 15px 20px;
  }

  @media (max-width: 499px) {
    font-size: 0.8rem;
    padding: 15px 10px;
  }
`;

const StyledBanner = styled.img`
  width: 35vw;
  min-width: 240px;
  max-width: 300px;
  object-fit: contain;
  position: absolute;
  top: 2.4rem;

  left: 50%;
  transform: translateX(-50%);
  opacity: 0.5;

  @media (max-width: 699px) {
    top: 5rem;
    width: 35vw;
    min-width: 200px;
    max-width: 250px;
  }

  @media (max-width: 499px) {
    top: 5.5rem;
  }
`;

const StyledH3 = styled.h3`
  text-align: center;
  margin: 0;
  margin-top: 2rem;
  font-size: 1.2rem;
  opacity: 0.6;

  @media (max-width: 1049px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.5rem;
  }

  @media (max-width: 599px) {
    font-size: 1rem;
  }

  @media (max-width: 449px) {
    font-size: 0.8rem;
  }
`;

const HomePage = () => {
  const { getTranslation } = useLanguage();
  const products = getTranslation("products");
  ScrollToTop();

  return (
    <>
      <StyledBanner src={Banner} alt="Banner" />
      <HeadingsDiv>
        <HeadingsH2>{getTranslation("homepage.heading1")}</HeadingsH2>
        <HeadingsH2>{getTranslation("homepage.heading2")}</HeadingsH2>
        <HeadingsH2>{getTranslation("homepage.heading3")}</HeadingsH2>
      </HeadingsDiv>
      <StyledH3>
        <span>{getTranslation("homepage.howItWorks1")}</span>
        <span>{getTranslation("homepage.howItWorks2")}</span>
      </StyledH3>
      <StyledDiv>
        {products.map((product, index) => (
          <ProductCard key={index} product={product} />
        ))}
      </StyledDiv>
    </>
  );
};

export default HomePage;
